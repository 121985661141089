import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { RoutableWidget, RouterService } from "@backbase/foundation-ang/core";
import { ComplaintGetStartedComponent } from './components/complaint-get-started/complaint-get-started.component';
import { ComplaintRelateToComponent } from './components/complaint-relate-to/complaint-relate-to.component';
import { ComplaintTellUsComponent } from './components/complaint-tell-us/complaint-tell-us.component';
import { ComplaintAboutYourVehicleComponent } from './components/complaint-about-your-vehicle/complaint-about-your-vehicle.component';
import { MessageService } from '@wss/service/message.service';
import { DatastoreService } from '@wss/service/datastore.service';
import { ComplaintYourContactDetailsComponent } from './components/complaint-your-contact-details/complaint-your-contact-details.component';
import { DataService } from './services/data.service';
import { ComplaintSubmissionComponent } from './components/complaint-submission/complaint-submission.component';
import { DeactivationGuard } from '../guard/deactivation.guard';
import { ComplaintsGuard } from '../guard/complaints-auth-guard.guard';
import { AuthGuard } from '../guard/auth-guard';

@Component({
  selector: 'bb-complaint-widget',
  templateUrl: 'complaint-widget.component.html',
  providers: [RouterService],
  changeDetection: ChangeDetectionStrategy.Default,
})
@RoutableWidget({
  routes: [
    { path: '', redirectTo: 'get-started', pathMatch: 'full' },
    { path: 'get-started', component: ComplaintGetStartedComponent, canActivate: [ComplaintsGuard], canDeactivate: [DeactivationGuard] },
    { path: 'relate-to', component: ComplaintRelateToComponent, canActivate: [ComplaintsGuard], canDeactivate: [DeactivationGuard] },
    { path: 'tell-us', component: ComplaintTellUsComponent, canActivate: [ComplaintsGuard], canDeactivate: [DeactivationGuard] },
    { path: 'about-vehicle', component: ComplaintAboutYourVehicleComponent, canActivate: [ComplaintsGuard], canDeactivate: [DeactivationGuard] },
    { path: 'your-contact-details', component: ComplaintYourContactDetailsComponent, canActivate: [ComplaintsGuard], canDeactivate: [DeactivationGuard] },
    { path: 'complaint-submission', component: ComplaintSubmissionComponent, canActivate: [ComplaintsGuard], canDeactivate: [AuthGuard] }
  ],
})
export class ComplaintWidgetComponent implements OnInit, OnDestroy {
  currentStep: number = 0;
  concernTypeVehicle: boolean = false;
  totalSteps: number = 0;

  constructor(
    private messageService: MessageService,
    public datastoreService: DatastoreService,
    private formDataService: DataService,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.updateStepper();
    this.messageService.setActiveNav("Help centre internal");
    this.renderer.addClass(document.body, 'complaints-page');
    this.formDataService.concernType.subscribe(type => {
      if (type) {
        this.concernTypeVehicle = type == 'Vehicle' ? true : false;
        this.totalSteps = type == 'Vehicle' ? 4 : 3;
      } else {
        this.concernTypeVehicle = sessionStorage.getItem('ConcernType') as string == 'Vehicle' ? true : false;
        this.totalSteps = sessionStorage.getItem('ConcernType') as string == 'Vehicle' ? 4 : 3;
      }
    });
  }

  updateStepper() {
    this.messageService.getCurrentStep().subscribe(step => {
      this.currentStep = step;
      this.datastoreService.setStepperStep(step);
    });
  }

  ngOnDestroy(): void {
    this.formDataService.deleteAllFormData();
    this.renderer.removeClass(document.body, 'complaints-page');
  }
}
