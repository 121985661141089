<ng-container *ngIf="helpCentreWidgetItem">   
    <div class="help-centre-widget-container">
        <div class="help-centre-title">
            <span class="section-label-title">
                {{helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsPageHeader}}</span>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsFirstTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsInitialInstructions"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsContactUsAccordionTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="help-centre-support margin-top-30">
            <bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsContactUsAccordion"></bb-common-expansion-panel>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintConfirmationParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsSupportingEvidenceTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsSupportingEvidenceParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsResolvingTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="customer-support-text margin-top-10">
            <div>
                <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsResolvingParagraph"></bb-content-html>
            </div>
        </div>
        <div class="title-header margin-top-20">
            <h3>
                <strong>
                    <bb-content-html [html]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsCommonQuestionsTitle"></bb-content-html>
                </strong>
            </h3>
        </div>
        <div class="help-centre-support margin-top-30">
            <bb-common-expansion-panel [accordionContent]="helpCentreWidgetItem.helpCentreVehicleComplaints.helpCentreVehicleComplaintsCommonQuestionsAccordion"></bb-common-expansion-panel>
        </div>
    </div>
</ng-container>
