import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterService } from '@backbase/foundation-ang/core';
import { ContentService } from '@backbase/universal-ang/content';
import { BalloonRefinanceItem, BalloonRefinanceSavedQuote } from '@wss/model/balloon-refinance/balloon-refinance';
import { Observable, Subject } from 'rxjs';
import { DatastoreService } from '@wss/service/datastore.service';
import { TealiumUtagService } from '@wss/service/utag.service';
import { WSSAppConstant, appConfig } from '@wss/config/wss-app-constants';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { BalloonRefinanceService } from '../../services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from '@wss/service/message.service';

@Component({
  selector: 'bb-br-get-quote',
  templateUrl: './br-get-quote.component.html'
})

export class BrGetQuoteComponent implements OnInit {

  quoteForm: FormGroup = this.formBuilder.group({
    loanTerm: ['', Validators.required]
  });

  balloonRefinanceWidgetItem$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');

  openOptionCont: boolean = false;
  disableBtn: boolean = false;
  incrementer: boolean = true;
  decrementer: boolean = true;
  counterType: string = 'increment';

  accountData: any;
  setTimer: any;

  monthlyPayment: number = 0.00;
  finalPayment:number = 0;
  refinanceAmount: number = 0;
  optionalFinalPayment:number = 0;
  apr:number = 0;
  totalAmount:number = 0; 
  totalInterest:number = 0;
  agreementType:string = 'HP';
  termLength:any = '';
  optionFee:number = 0;
  accountDetails: any;
  getQuoteBFItem: any;
  hasBeenTouched: boolean = false;
  svgInfoIcon: any = WSSAppConstant.SVG_INFO_ICON;
  mouseEventState: boolean = false;
  proposalData: ProposalRequest;
  isQuoteReturnJourney: boolean;
  commonNavTestID: string = 'wss-Br-get-a-quote-';
  readonly destroy$ = new Subject();
  serviceError: boolean = false;
  vehicleRegYear: number = 2013;
  vehicleType: string = 'General';
  vehicleAge: number = 0;
  totalVehicleTerm: number = 0;
  termAccepted: number = 0;
  saveAndReviewBtnDisable: boolean = false;
  isMotorbike: boolean = false;
  termIn12To60Range: boolean = false;
  ageLimitError: boolean = false;
  minValueLimitError: boolean = false;
  maxValueLimitError: boolean = false;
  over12MonthAgeErrorMessage: string = "";
  
  constructor(
    private modalService: NgbModal,
    private router: RouterService,
    private bbContentService: ContentService,
    private formBuilder: FormBuilder,
    private datastoreService: DatastoreService,
    private viewportScroller: ViewportScroller ,
    private elementRef: ElementRef,
    private tealiumService: TealiumUtagService,
    private balloonRefinanceSrv: BalloonRefinanceService,
    private changeDetectorRef: ChangeDetectorRef,
    private eocService: EndOfContractService,
    private messageService: MessageService
    ) { 
      this.proposalData = this.datastoreService.getProposalRequest() 
      this.isQuoteReturnJourney = this.datastoreService.getIsReturnJourneyBrQuotePage();
    }

  ngOnInit(): void {
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_Quote', URI: '/dashboard#/balloon-refinance/get-a-quote' });
    this.isPreviousJourney();
    this.accountDetails = this.datastoreService.getAccountDetails();
    this.getAPR();
    this.checkAgeVehicle();
    this.checkForAgeOrValueErrors();
  }

  diffDateMonths(date1: any, date2: any) {
    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getYear() - date2.getYear();
    return monthDiff + yearDiff * 12;
  }

  isPreviousJourney() {
    if (this.isQuoteReturnJourney) {
      this.termLength = this.proposalData?.proposed_term;
      this.hasBeenTouched = true;
    }
  }

  enableApplyBtn() {
    if(this.minValueLimitError || (this.ageLimitError && this.termAccepted < 12) || this.maxValueLimitError){
      this.quoteForm.controls['loanTerm'].disable();
    }
    this.disableBtn = this.termIn12To60Range && !this.minValueLimitError && !this.ageLimitError && !this.maxValueLimitError ? false : true;
  }

  setCountArrows() {
    if(this.minValueLimitError || (this.ageLimitError && this.termAccepted < 12) || this.maxValueLimitError){
      this.decrementer = false;
      this.incrementer = false;
    }
    else{
      this.decrementer = this.termLength > 12 ?  true : false;
      this.incrementer = this.termLength < 60 ?  true : false;
    }
  }

  checkAgeVehicle() {
    let getVehicleTypeTemp = this.accountDetails.cap_code;
    let getVehicleYearTemp = this.accountDetails.registration_year;
    let getVehicleMonthTemp = this.accountDetails.registration_month;
    let getVehicleDateTemp: any = new Date(getVehicleYearTemp, getVehicleMonthTemp - 1); 
    let getCurDate: any = new Date();
    let getVehicleType = getVehicleTypeTemp.substr(11,1);
    let getTermMonths: any = this.diffDateMonths(getCurDate, getVehicleDateTemp);
    if(getVehicleType === 'E') {
      this.vehicleType = 'Electric';
      this.termAccepted = appConfig.vehicleElectricAge - getTermMonths;
    } else {
      this.termAccepted = appConfig.vehicleGeneralAge - getTermMonths;
    }
    this.vehicleAge = getTermMonths;     
  }

  getAPR() {
    this.balloonRefinanceWidgetItem$.subscribe((res: any) => {      
      this.getQuoteBFItem = res;
      this.apr = this.getQuoteBFItem.balloonRefinanceGetAQuote?.quoteAPRPercentage;
      this.checkQuoteDate();
    });
  }

  calculateAPR() { 
    if(this.termLength !== '') {
      this.apr = this.getQuoteBFItem?.balloonRefinanceGetAQuote?.quoteAPRPercentage;
      this.apr = Math.pow(((this.apr / 100) + 1), (1 / 12)) - 1; 
      this.calculateMonthlyPayment(this.apr);
    }
  }

  calculateMonthlyPayment(aprVal:any) {
    let monthlyRatePlus = aprVal + 1;
    let calc1 = Math.pow(monthlyRatePlus, this.termLength);
    let elapsedRate = calc1 - 1;
    let adjustRate = calc1 *  aprVal;
    let totalAmountTemp = 0;
    adjustRate = (adjustRate === 0) ? 1 : adjustRate;
    let getFullAPR = elapsedRate / adjustRate;
    getFullAPR = (getFullAPR === 0) ? this.termLength : getFullAPR;
    let monthlyPayTemp:number = this.finalPayment / getFullAPR;
    this.monthlyPayment = parseFloat((Math.ceil(monthlyPayTemp * 100) / 100).toFixed(2)); 
    totalAmountTemp = this.termLength * this.monthlyPayment;
    this.totalInterest = parseFloat((Math.ceil((totalAmountTemp - this.finalPayment) * 100) / 100).toFixed(2)); 
    this.totalAmount = this.optionFee + this.finalPayment + this.totalInterest;
    this.saveData();
  }

  calculateInitials() {
    this.accountData = this.datastoreService.getMyAccount();
    let finalPaymentAmount = this.accountData?.additionalInfoData['optionaFinalPayment'];
    let optionFeeTemp =  this.accountData?.additionalInfoData['optionFee'];
    optionFeeTemp = optionFeeTemp?.substr(1);
    this.optionFee = parseInt(optionFeeTemp);
    this.finalPayment = parseInt(finalPaymentAmount?.replace(',', ''));
    this.refinanceAmount = this.finalPayment;
  }

  saveData() {
    let savedQuote: BalloonRefinanceSavedQuote = {
      balloonRefinance: this.refinanceAmount,
      balloonPayment: this.finalPayment,
      monthlyPayment: this.monthlyPayment,
      termLength: this.termLength,
      optionalFinalPayment: this.optionalFinalPayment,
      totalInterest: this.totalInterest,
      totalAmount: this.totalAmount
    }
    this.datastoreService.setQuoteData(savedQuote);   
  }

  checkQuoteDate() {
    this.accountData = this.datastoreService.getMyAccount();
    let getQuoteData = this.datastoreService.getQuoteData(); 
    if(getQuoteData?.termLength >= 12 && this.isQuoteReturnJourney === false){
      let optionFeeTemp =  this.accountData?.additionalInfoData['optionFee'];
      optionFeeTemp = optionFeeTemp?.substr(1);
      this.hasBeenTouched = true;
      this.refinanceAmount = getQuoteData.balloonRefinance;
      this.monthlyPayment = getQuoteData.monthlyPayment;
      this.finalPayment = getQuoteData.balloonPayment;
      this.optionalFinalPayment = getQuoteData.optionalFinalPayment;
      this.termLength = getQuoteData?.termLength;
      this.totalInterest = getQuoteData.totalInterest;
      this.totalAmount = getQuoteData.totalAmount;
      this.optionFee = parseInt(optionFeeTemp);
      this.setCountArrows();
      this.enableApplyBtn();
    } else {      
      this.calculateInitials();
      this.calculateAPR();
      this.setCountArrows();
    }
  }
  
  checkVehicleAge(): boolean {
    this.totalVehicleTerm = this.termLength + this.vehicleAge;
    if((this.termLength > 11) && (this.termLength < 61)) {
      this.termIn12To60Range = true;
      if((this.vehicleType === 'Electric' && this.totalVehicleTerm < (appConfig.vehicleElectricAge + 1)) || (this.vehicleType === 'General' && this.totalVehicleTerm < (appConfig.vehicleGeneralAge + 1))) {
        this.ageLimitError = false;
        return true;
      } else {
        this.ageLimitError = true;
        return false;
      }
    } else {
      this.termIn12To60Range = false;
      this.checkIfMeetsMinAge();
      return false;
    }
  }

  termLengthHandler(event:any) {
    this.termLength = event.target?.value != '' ? parseInt(event.target?.value): '';   
    if(this.checkVehicleAge()) { 
      this.termSuccess();
      if (this.isQuoteReturnJourney) {
        this.saveAndReviewBtnDisable = false;
      }   
    } else {
      if((this.termLength < 12) || (this.termLength > 60)) {
        if (this.isQuoteReturnJourney) {
          this.saveAndReviewBtnDisable = true;
        }        
        this.hasBeenTouched = true;
      } else {        
        this.hasBeenTouched = false;
      }           
    }
    this.enableApplyBtn();
  }

  termSuccess() {
    this.calculateAPR();
    this.setCountArrows();    
    this.hasBeenTouched = true;
  }

  updateTerm(val: string) {
    if(!this.minValueLimitError && !this.maxValueLimitError && !(this.ageLimitError && this.termAccepted < 12)){
      if(val === 'decrement' && this.termLength >= 12 && this.termLength <= 60) {
        this.termLength = this.termLength - 1;
        this.calTermOnUpdate();
      }
      if(val === 'increment' && this.termLength >= 12 && this.termLength <= 60) { 
        this.termLength = this.termLength + 1;
        this.calTermOnUpdate();
      }
      if(this.termLength === '' || this.termLength < 12){
        this.termLength = 12;
        this.calTermOnUpdate();
      }
      if(this.termLength >= 61){
        this.termLength = 60;      
        this.calTermOnUpdate();
      }
      this.setCountArrows();    
      this.enableApplyBtn();
      this.saveAndReviewBtnDisable = false;
      }
  }


  calTermOnUpdate() {
    this.hasBeenTouched = false;
    if(!this.checkVehicleAge()) {
      this.calculateAPR();
    }
  }

  setTimerIncement(termVal:any, countType: string) {
    if(!this.minValueLimitError && !this.maxValueLimitError && !(this.ageLimitError && this.termAccepted < 12)){
      this.termLength = termVal == "" ? 0 : termVal;
    }
    this.counterType = countType;
    this.setTimer = setInterval(() => {this.setTimeAdd()}, 500);
  }

  setTimeAdd() {
    this.termLength = this.counterType === 'increment' ? this.termLength <= 59 ? this.termLength + 1 : this.termLength : this.termLength > 12 ? this.termLength - 1 : this.termLength;
    this.elementRef.nativeElement.querySelector("#wss-br-loanterm").value = this.termLength;
  }

  clearTimer() {
    clearInterval(this.setTimer);
    if(this.checkVehicleAge()) {        
      this.setCountArrows(); 
      this.calculateAPR();
      this.enableApplyBtn();
    }
  }

  openOptionFeeContent(elemt: string) {    
    this.openOptionCont = true;
    this.viewportScroller.scrollToAnchor(elemt);
  }

  updateOption() {    
    this.openOptionCont = false;
  }

  navigate(): void {
    const isOutOfHours = new Date().getHours() >= 22 || new Date().getHours() < 8;
    const isEoc = this.eocService.isContractWithinDays(89);
    if(isOutOfHours) {
      this.router.navigate(['./cta-out-of-hours'])
    } else if(!isEoc) {
      this.router.navigate(['/balloon-refinance/cta-eoc'])
    } else if (this.accountDetails?.is_dd_calling) {
      this.router.navigate(['/balloon-refinance/cta-dd-calling'])
    } else {
      this.router.navigate(['./get-started'])
    }    
  }

  extractGbpFromString(val: string) {
    let valueWithoutGbpSymbol = val?.replace('£', '');
    return parseInt(valueWithoutGbpSymbol);
  }

  navigateToHome(): void {
    this.router.navigate(['/eoc-options/landing']);
  }

  onContinue(e: any) {
    this.saveDataProposal('/balloon-refinance-getdetails/summary');  
  }

  saveDataProposal(url: string) {
    if(this.proposalData) {
      this.proposalData.proposed_term = this.termLength;
      this.balloonRefinanceSrv.postProposalDetails(this.proposalData).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.proposalData = this.datastoreService.getProposalRequest();
        this.router.navigate([url])
      }, 
      (error: any) => {
        this.showError();
      });
    } else {
      this.showError();
    }    
  }

  showError(): void {
    this.datastoreService.setErrorServer();
    this.serviceError = true;
    this.changeDetectorRef.detectChanges();
  }

  onApplyClick() {
    this.router.navigate(['/balloon-refinance/get-started']);  
  }

  checkIfGdsCodeIsMotorbike(){
    let gdsCode = this.accountDetails?.goods_code;
    if(appConfig.motorbikeGdsCodes.includes(gdsCode)){
      this.isMotorbike = true;
    }
  }

  checkIfMeetsAdvance(){
    let minAdvance = this.isMotorbike ? appConfig.bikeMinAdvance : appConfig.carMinAdvance;
    let finalPaymentAmountNumericValue = parseFloat(this.accountDetails.final_payment_amount.replace(/[^0-9.-]+/g, ''));
    if(finalPaymentAmountNumericValue > appConfig.maxAdvance){
      this.maxValueLimitError = true;
    }
    else if(finalPaymentAmountNumericValue < minAdvance){
      this.minValueLimitError = true;
    }
  }

  checkIfMeetsMinAge() {
    this.totalVehicleTerm = this.termLength + this.vehicleAge;
    if((this.vehicleType === 'Electric') && (this.totalVehicleTerm < (appConfig.vehicleElectricAge + 1))) {
      this.ageLimitError = false;
    } else if ((this.vehicleType === 'General') && (this.totalVehicleTerm < (appConfig.vehicleGeneralAge + 1))) {
      this.ageLimitError = false;
    } else {
      this.ageLimitError = true;
    }
  }

  createOver12MonthAgeErrorMessage(){
    this.balloonRefinanceWidgetItem$.subscribe((res: any) => {      
      this.getQuoteBFItem = res;
      let vehicleAgeErrorStartText = this.getQuoteBFItem.balloonRefinanceGetAQuote?.vehicleAgeError;
      let quoteTermText = this.getQuoteBFItem.balloonRefinanceGetAQuote?.quoteTermText;
      let vehicleAgeErrorEndText = this.getQuoteBFItem.balloonRefinanceGetAQuote?.vehicleAgeErrorEndTxt
      this.over12MonthAgeErrorMessage = (`${vehicleAgeErrorStartText} ${this.termAccepted} ${quoteTermText} ${vehicleAgeErrorEndText}`);
    });
  }

  checkForAgeOrValueErrors(){
    this.checkIfGdsCodeIsMotorbike(); 
    this.checkIfMeetsAdvance();
    this.checkIfMeetsMinAge();
    this.createOver12MonthAgeErrorMessage();
    this.enableApplyBtn();
  }
}