import {ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from "rxjs";
import { ContentService } from '@backbase/universal-ang/content';
import { HelpCentreItem } from '@wss/model/helpCentre';
import { TealiumUtagService } from '@wss/service/utag.service';
import { PageConfig, PAGE_CONFIG } from '@backbase/foundation-ang/web-sdk';
import {  injectBrandingIntoAccordion } from '@wss/common/util/util';
import { ContentData } from '@wss/config/wss-app-constants';

@Component({
  selector: 'bb-vehicle-complaints',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './vehicle-complaints.component.html',
  styles: [],
  providers: [ ContentService ]
})
export class VehicleComplaintsComponent implements OnInit{
  contentTxt: string = ContentData.HelpCentreVehicleComplaints;
  content: Array<any> = [];
  contentFolder: string = ContentData.HelpCentre;
  helpCentreContent: string = 'help-centre-vehicle-complaints';
  param: any;
  helpCentreWidgetItem$: Observable<HelpCentreItem | undefined> = this.bbContentService.getContent<HelpCentreItem>('helpCentreWidgetItem');
  helpCentreWidgetItem = new HelpCentreItem();
  portalName: string = '';

  constructor(
    private readonly bbContentService: ContentService,
    @Inject(PAGE_CONFIG) private pageConfig: PageConfig,
    private tealiumService: TealiumUtagService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.portalName = this.pageConfig.portalName || 'blackhorse';
    this.tealiumService.view(
      {
        JourneyName: 'Help Centre',
        JourneyStepName: 'Vehicle Complaints',
        CanonicalPath: window.location.pathname + window.location.hash.substring(1).split("?")[0]
      }
    );
    this.getContentData();
  }

  getContentData() {
    this.helpCentreWidgetItem$.subscribe((pageItem: any) => {
      this.helpCentreWidgetItem = pageItem;
      this.helpCentreWidgetItem.helpCentreVehicleComplaints = injectBrandingIntoAccordion(this.helpCentreWidgetItem.helpCentreVehicleComplaints, this.helpCentreWidgetItem.brandContactDetails);
      this.changeDetectorRef.detectChanges();
    })    
  }
}
