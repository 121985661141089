<section class="balloon-refinance" *ngIf="balloonRefinanceWidgetItem$ | async as brWidgetItem">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message></bb-wss-banner-message>
    </div>
    <div *ngIf="isResumableBrApplication && !isBrLandingPageResumeBrAppBannerClosed">
        <bb-wss-banner-message [isBrLandingPageBanner]="isBrLandingPageBanner" [resumeBrApplicationLinkText]="resumeBrApplicationLinkText"
            [resumeBrApplicationLink]="resumeBrApplicationLink" [continueApplicationText]="continueApplicationText"
            [eocBrReturnAndSignText]="eocBrReturnAndSignText" [proposalStatus]="proposalStatus"[isDecisionChanged]="isDecisionChanged"
            [reviewYourApplicationResultText]="reviewYourApplicationResultText">
        </bb-wss-banner-message>
    </div>

    <div class="section-title-container">
        <h1 class="section-label-title" test_id="pageTitle">
            {{brWidgetItem.balloonRefinanceLanding.pageTitle}}</h1>
    </div>
    <section class="content-width single-column">
        <div class="mb-20px">
            <div class="mb-20px" test_id="textBelowPageHeaderFirstSection">
                <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.bodyFirstSection"></bb-content-html>
            </div>
        </div>
        <div>
            <div class="h2">
                <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.howItWorksSectionHeader"></bb-content-html>
            </div>
            <ol class="mt-15px" id="brContHowWorks">
                <ng-container 
                    *ngFor="let item of brWidgetItem.balloonRefinanceLanding.howItWorksSectionListItems; let i = index">
                    <li>
                        <bb-content-html [html]="item.content"></bb-content-html>
                    </li>
                </ng-container>
            </ol>
        </div>
        <div class="mb-40px">
            <div test_id="whatYouNeedToKnowText" class="h2 mb-25px pt-10px">
                <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.whatYouNeedToKnowText"></bb-content-html>
            </div>
            <div class="container-row expansion-border-hidden transparent-accordion balloon-refinance-accordion-header balloon-refinance-accordion-header-color">
                <bb-common-expansion-panel
                    [accordionContent]="brWidgetItemAccordion"
                    testIDText="brWidgetItemAccordion">
                </bb-common-expansion-panel>
            </div>
        </div>
        <div class="card mb-5">
            <div class="card-content text-center">
                <div class="p-mb-0px" test_id="makeYourSelectionContent">
                    <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.cardContent"></bb-content-html>
                </div>
                <div class="d-flex justify-content-center">
                    <button class="wss-button" (click)="onQuote(landingModal)" test_id="buttonText" id="wss-br-btn-landing-getquote">
                        {{brWidgetItem.balloonRefinanceLanding.buttonText}}
                    </button>
                </div>
                <div class="mb-p-0" id="wss-br-landing-backlink">
                    <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.backToPageLinkText"></bb-content-html>
                </div>
            </div>
            <div class="card-footer" test_id="haveQuestionsText" *ngIf="brWidgetItem.balloonRefinanceLanding.cardFooter">
                <span class="margin-top-10 mb-p-0 text-center">
                    <bb-content-html [html]="brWidgetItem.balloonRefinanceLanding.cardFooter"></bb-content-html>
                </span>
            </div>
        </div>
    </section>

    <ng-template #landingModal let-modal>
        <div class="modal-body save-exit-modal-border">
            <div class="container-row">
                <div class="container-column m-auto text-center pd-lt-10pt-rt-5pt">
                    <div test_id="confirmPopupText">
                        <div class="content-text-bold" test_id="landingModalHeader">
                            <bb-content-html [html]="proposalStatus === 'REFERRED' || isDecisionChanged === true ? brWidgetItem.commonNavigation.reviewYourApplicationResultHeader : proposalStatus === 'ACCEPTED' && isDecisionChanged === false ? 
                            brWidgetItem.commonNavigation.returnToSignAgreementHeader : brWidgetItem.commonNavigation.modalLandingHeader"></bb-content-html>    
                        </div>
                        <div  test_id="LandingModalContent">
                            <bb-content-html [html]="proposalStatus === 'REFERRED' || isDecisionChanged === true ? brWidgetItem.commonNavigation.reviewYourApplicationResultContent : proposalStatus === 'ACCEPTED' && isDecisionChanged === false ? 
                            brWidgetItem.commonNavigation.returnToSignAgreementContent : brWidgetItem.commonNavigation.modalLandingContent"></bb-content-html>
                        </div>
                    </div>
                    <div>
                        <button class="wss-button" (click)="onPrimaryButtonOnModalClick();" test_id="landingModalBtn" id={{landingModalPrimaryBtnId}}>
                            {{proposalStatus === 'REFERRED' || isDecisionChanged === true ? brWidgetItem.commonNavigation.reviewYourApplicationResultText : proposalStatus === 'ACCEPTED' && isDecisionChanged === false ? 
                            brWidgetItem.commonNavigation.returnToSignAgreementButtonText : brWidgetItem.commonNavigation.modalLandingPriBtnTxt}}
                        </button>
                        <br/>
                        <a href="javascript:void(0)" (click)="onCancelLink()" test_id="landingModalLink" id="wss-br-landing-model-cancellink">{{brWidgetItem.commonNavigation.modalLandingSecBtnTxt}}</a>                        
                    </div>
                </div>
                <div>
                    <span (click)="modal.dismiss()" test_id="LandingModalCancel" class="material-icons material-icon-grey cursor-link">clear</span>
                </div>
            </div>    
        </div>
    </ng-template>
</section>