<div *ngIf="complaintWidgetItem$ | async as complaintWidgetItem">
  <form [formGroup]="concernSelectionForm" formInvlidControlScroll>
    <div class="container-column">
      <div class="help-centre-title">
        <h1 class="section-label-title" test_id="makeComplaintPageName">
          {{complaintWidgetItem.complaintGetStarted.pageTitle}}</h1>
      </div>

      <div class="mt-30px" test_id="makeComplaintInfo">
        <bb-content-html [html]="complaintWidgetItem.complaintGetStarted.makeComplaintInfo"></bb-content-html>
      </div>

      <div class="mt-15px">
        <bb-info-alert [infoContent]="complaintWidgetItem.complaintGetStarted.consentInfo"></bb-info-alert>
      </div>

      <div class="form-group mt-15px">
        <div test_id="concernHeadline">
          <h2>{{complaintWidgetItem.complaintGetStarted.concernHeadline}}</h2>
        </div>
        <div class="mt-20px" test_id="concernSubTitleText">
          <bb-content-html [html]="complaintWidgetItem.complaintGetStarted.concernSubTitleText"></bb-content-html>
        </div>
        <div class="additional-info-mc" test_id="motorCommissionAdditionalInfo" *ngIf="showAdditionalInfo">
          <strong>{{complaintWidgetItem.complaintGetStarted.motorCommissionAdditionalInfoText}}</strong>
        </div>
        <div class="radio mt-20px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="concernType" value="Vehicle"
              id="vehicleOptionSelection"
              [ngClass]="{'has-Error-Radio': submitted && concernSelectionForm.get('concernType').errors && concernSelectionForm.get('concernType').invalid && concernSelectionForm.get('concernType').errors.required}"
              (change)="handleChangeEventRadioBtn()"><span class="label"
              test_id="vehicleRadioSelection">{{complaintWidgetItem.complaintGetStarted.vehicalLabel}}</span>
            <span class="normalText mt-10px">{{complaintWidgetItem.complaintGetStarted.vehicalLabelInfoText}}</span>
          </label>
        </div>
        <div class="radio mt-20px">
          <label class="form-check-label">
            <input type="radio" class="form-check-input" formControlName="concernType" value="FinanceAgreement"
              id="financeAgreementOptionSelection"
              [ngClass]="{'has-Error-Radio': submitted && concernSelectionForm.get('concernType').errors && concernSelectionForm.get('concernType').invalid && concernSelectionForm.get('concernType').errors.required}"
              (change)="handleChangeEventRadioBtn()"><span class="label"
              test_id="financeAgreementRadioSelection">{{complaintWidgetItem.complaintGetStarted.financeAgreementLabel}}</span>
            <bb-content-html class="normalText"
              [html]="complaintWidgetItem.complaintGetStarted.financeAgreementLabelInfoText"></bb-content-html>
          </label>
        </div>
        <div class="required-error" *ngIf="submitted && concernSelectionForm.get('concernType').errors?.required">
          <small class="text-danger" test_id="concernTypeError">
            {{ complaintWidgetItem.complaintRelateTo.pleaseSelectAnOptionErrorMessage }}
          </small>
        </div>
      </div>
      <div class="">
        <bb-info-alert [infoContent]="complaintWidgetItem.complaintGetStarted.getInTouchInfo"></bb-info-alert>
      </div>
      <div class="complaints-btn-group">
        <button class="wss-button-secondary" test_id="cancelButton" (click)="handleCancelBtn()"
          id="getStartedCancelButton">
          {{complaintWidgetItem.complaintGetStarted.secondaryButtonText}}
        </button>
        <button class="wss-button" test_id="nextButton" id="getStartedNextButton"
          (click)="handleNextBtn()">{{complaintWidgetItem.complaintGetStarted.primaryButtonText}}</button>
      </div>
    </div>
  </form>

  <ng-template #content let-modal>
    <div class="container-column popup-modal">
      <div class="container-row modal-header-c" test_id="popupClose">
        <span class="material-icons" (click)="stayBack();modal.dismiss('Cross click');">clear</span>
      </div>
      <div class="container-column modal-body-c" test_id="journeyChangeUnsavedChangesText" id="journeyChangeUnsavedChangesText">
        <bb-content-html [html]="complaintWidgetItem.complaintGetStarted.journeyChangeUnsavedChangesText">
        </bb-content-html>
      </div>
      <div class="container-row modal-footer-c para-inline" test_id="popupButtons">
        <button class="wss-button"
          (click)="closeJourneyChangePopup();modal.dismiss('Cross click');">{{complaintWidgetItem.complaintGetStarted.journeyChangeProceedButtonText}}</button>
        <button class="wss-button-secondary"
          (click)="stayBack();modal.dismiss('Cross click')">{{complaintWidgetItem.complaintGetStarted.journeyChangeStayButtonText}}</button>
      </div>
    </div>
  </ng-template>
</div>