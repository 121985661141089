<section id="br-getdetails-summary">
    <div role="alert" *ngIf="serviceError">
        <bb-wss-banner-message [errorCode]="errorCode"></bb-wss-banner-message>
    </div>
    <wss-page-title  [pageTitle]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.pagetitle">
    </wss-page-title>
    <section class="content-width-max"> 
        <div class="mt-25px-mb-30px" test_id="textBelowTitle"> 
            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.textBelowHeader"></bb-content-html>
        </div>
        <div class="section-sub-title mb-20px">
            {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.reviewYourAnswersHeader}}
        </div>
        <div class="container-row expansion-border-hidden transparent-accordion balloon-refinance-accordion-header balloon-refinance-accordion-header-color">
            <mat-accordion class="accordion mat-accordion-override w-100">   
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override" [expanded]="isQuoteAccordionExpanded">
                    <mat-expansion-panel-header>                
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold get-a-quote-accordions-font-color font-size-16" test_id="quoteAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <h4 test_id="quoteTitle" class="mb-20px mt-20px ml-5x">
                        {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTitle}}
                    </h4>               
                    <div class="quote-items-container">
                        <div class="quote-item">
                            <div class="wss-add-box-icon quote-icons quote-icons-flat"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteTotalInterestTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalInterestPopoverText" [popOverId]="'summaryTotalInterestPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTotalInterestText" class="font-size-16 line-height-24">
                                    {{totalInterest | currency:'GBP':'symbol'}}
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-direct-debit-icon quote-icons quote-icons-refinance"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteRefinanceAmountTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteRefinanceAmountPopoverText" [popOverId]="'summaryRefinanceAmountPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteRefinanceAmountText" class="font-size-16 line-height-24">
                                    {{refinanceAmount | currency:'GBP':'symbol'}}
                                </span>                               
                            </div>                            
                        </div>
                        <div class="quote-item">
                            <div class="wss-chart-icon quote-icons quote-icons-flat"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteAPRTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAPRTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAPRPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAPRPopoverText" [popOverId]="'summaryAPRPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteAPRText" class="font-size-16 line-height-24">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAPRPercentage}}%
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-receipt-icon quote-icons quote-icons-normal"></div>                            
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteTotalAmountTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTotalAmountPopoverText" [popOverId]="'summaryTotalAmountPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTotalAmountText" class="font-size-16 line-height-24">
                                    {{totalPayableAmt | currency:'GBP':'symbol'}}*
                                </span>                                
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-calendar-month-icon quote-icons quote-icons-normal"></div>                            
                            <div class="quote-details-item">  
                                <span class="quote-details-title" test_id="quoteTermTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTermTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTermPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteTermPopoverText" [popOverId]="'summaryTermPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteTermText" class="font-size-16 line-height-24">
                                    {{proposalData.proposed_term}}
                                </span>
                            </div>
                        </div>
                        <div class="quote-item">
                            <div class="wss-car-light-icon quote-icons quote-icons-car"></div>
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteAgreementTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAgreementTitle}}
                                    <ng-container *ngIf="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAgreementPopoverText">
                                        <wss-popover [popoverContent]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAgreementPopoverText" [popOverId]="'summaryAgreementPopoverId'"></wss-popover>
                                    </ng-container>
                                </span>
                                <span test_id="quoteAgreementText" class="font-size-16 line-height-24">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteAgreementText}}
                                </span>
                            </div>
                        </div>                     
                        <div class="quote-item">
                            <mat-icon class="text-black-50 quote-icons">credit_card</mat-icon>
                            <div class="quote-details-item">
                                <span class="quote-details-title" test_id="quoteMonthlyTitle">
                                    {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteMonthlyTitle}}
                                </span>
                                <span test_id="quoteAgreementText" class="font-size-16 line-height-24">
                                    {{monthlyPayment | currency:'GBP':'symbol'}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="quote-details-item" test_id="quoteOptionFeeText">
                        {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.quoteOptionFeeText}}                        
                    </div>
                    <button class="wss-button-secondary" test_id="editButtonTextQuote" id="wss-brg-summary-quoteEditBtn" (click)="navigateTo('/balloon-refinance/get-a-quote')">
                        {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                    </button>
                </mat-expansion-panel> 
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>                
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold get-a-quote-accordions-font-color font-size-16" test_id="yourVehicleAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.yourVehicleAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div *ngIf="proposalData">
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="vehicleMakeLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.vehicleMakeLabel"></bb-content-html>
                            </span>
                            <span>{{vehicleMake}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="modelLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.modelLabel"></bb-content-html>
                            </span>
                            <span>{{model}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="registrationLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.registrationLabel"></bb-content-html>
                            </span>
                            <span data-cs-mask>{{accountDetails.registration_no}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="mileageLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.mileageLabel"></bb-content-html>
                            </span>
                            <span>{{proposalData.mileage}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="vehicleUseLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.vehicleUseLabel"></bb-content-html>
                            </span>
                            <span>{{vehicleUse?.title}}</span>
                        </div>
                            <button class="wss-button-secondary mt-10px" test_id="editButtonTextVehicle" id="wss-brg-summary-vehicleEditBtn" (click)="navigateTo('/balloon-refinance-getdetails/vehicle')">
                                {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                            </button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold get-a-quote-accordions-font-color font-size-16" test_id="aboutYouAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.aboutYouAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="section-sub-title mb-20px" test_id="personalDetailsHeader">
                            {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.personalDetailsHeader}}
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="fullNameLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.fullNameLabel"></bb-content-html>
                            </span>
                            <span>{{customerDetails?.individual.title}} {{customerDetails?.individual.first_name}} {{customerDetails?.individual.surname}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="dobLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.dobLabel"></bb-content-html>
                            </span>
                            <span>{{dateOfBirth}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="nationalityLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.nationalityLabel"></bb-content-html>
                            </span>
                            <span *ngIf="nationality">{{nationality.title}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="maritialstatutsLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.maritialstatutsLabel"></bb-content-html>
                            </span>
                            <span *ngIf="maritalStatus">{{maritalStatus.title}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="dependantsLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.dependantsLabel"></bb-content-html>
                            </span>
                            <span>{{proposalData.no_of_dependants}}</span>
                        </div>                        
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="ResidentialStatusLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.residentialStatusLabel"></bb-content-html>
                            </span>
                            <span *ngIf="residentStatus">{{residentStatus.title}}</span>
                        </div>
                        <div class="mb-20px">
                            <div class="section-sub-title mb-20px" test_id="currentAddressHeader">
                                {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.currentAddressHeader}}
                            </div>
                            <div *ngIf="currentAddress">
                                <wss-address-display 
                                    [addressContent]="currentAddress[0]?.address_detail" 
                                    [displayClass]="classLiveAddress">
                                </wss-address-display>
                                <div class="mb-20px mt-20px">
                                    <span class="d-block mb-p-0" test_id="moveInLabel">
                                        <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.moveInDateLabel"></bb-content-html>
                                    </span>
                                    <span>{{currentAddress[0]?.moving_in_date}}</span>
                                </div>
                            </div>
                            
                        </div>
                        <ng-container *ngIf="additionalAdresses.length > 1">
                            <div *ngFor="let item of previousAddresses;let i = index;">
                                <div class="mb-20px">
                                    <div class="section-sub-title mb-20px" [id]="previousAddressLabelId+(i+1)">
                                        {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.previousAddressHeader}}&nbsp;0{{i+1}}
                                    </div>
                                    <wss-address-display 
                                        [addressContent]="item?.address_detail" 
                                        [displayClass]="classLiveAddress">
                                    </wss-address-display>
                                </div>
                                <div class="mb-20px">
                                    <span class="d-block mb-p-0" test_id="moveInLabel">
                                        <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.moveInDateLabel"></bb-content-html>
                                    </span>
                                    <span>{{item?.moving_in_date}}</span>
                                </div>
                            </div>
                        </ng-container>
                        <button class="wss-button-secondary mt-10px" test_id="editButtonTextAboutYou" id="wss-brg-summary-aboutYouEditBtn" (click)="navigateTo('/balloon-refinance-getdetails/about-you')">
                            {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                        </button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>                
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold get-a-quote-accordions-font-color font-size-16" test_id="employmentAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.employmentAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="employmentStatusLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.employmentStatusLabel"></bb-content-html>
                            </span>
                            <span *ngIf="proposalData.perm_employee || proposalData.occupation">Employed</span>
                            <span *ngIf="!(proposalData.perm_employee || proposalData.occupation)">Unemployed</span>
                        </div>
                        <div class="mb-20px" *ngIf="!(proposalData.perm_employee || proposalData.occupation)">
                            <span class="d-block mb-p-0" test_id="unemployedSituationLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.unemploymedSituationLabel"></bb-content-html>
                            </span>
                            <span *ngIf="employmentSector">{{employmentSector.title}}</span>
                        </div>
                        <div *ngIf="proposalData.perm_employee || proposalData.occupation">
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="contractTypeLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.contractTypeLabel"></bb-content-html>
                                </span>
                                <span *ngIf="proposalData.perm_employee">{{employmentType?.title}}</span>
                                <span *ngIf="proposalData.perm_employee === null">-</span>
                                <span *ngIf="proposalData.perm_employee === false">{{employmentType?.title}}</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="timeLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.timeLabel"></bb-content-html>
                                </span>
                                <span *ngIf="proposalData.full_time">{{employmentTenure?.title}}</span>
                                <span *ngIf="proposalData.full_time === null">-</span>
                                <span *ngIf="proposalData.full_time === false">{{employmentTenure?.title}}</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="sectorLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.sectorLabel"></bb-content-html>
                                </span>
                                <span>{{employmentSector?.title}}</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="occupationLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.occupationLabel"></bb-content-html>
                                </span>
                                <span *ngIf="proposalData.occupation">{{proposalData.occupation}}</span>
                                <span *ngIf="!proposalData.occupation">-</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="employerLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.employerLabel"></bb-content-html>
                                </span>
                                <span>{{proposalData.employer_name}}</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="startDateLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.startDateLabel"></bb-content-html>
                                </span>
                                <span>{{employeeStartDate}}</span>
                            </div>
                            <div class="mb-20px">
                                <span class="d-block mb-p-0" test_id="employerAddressLabel">
                                    <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.employerAddressLabel"></bb-content-html>
                                </span>
                                <div *ngIf="employerAddress">
                                    <wss-address-display 
                                        [addressContent]="employerAddress"
                                        [displayClass]="classLiveAddress">
                                    </wss-address-display>
                                </div>
                            </div>
                        </div>
                        <button class="wss-button-secondary mt-10px" test_id="editButtonTextEmployment" id="wss-brg-summary-employmentEditBtn" (click)="navigateTo('/balloon-refinance-getdetails/employment')">
                            {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                        </button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>                
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold get-a-quote-accordions-font-color font-size-16" test_id="financeAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.financeAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="annualIncomeLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.annualIncomeLabel"></bb-content-html>
                            </span>
                            <span>£{{proposalData.gross_annual_income}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="shareOfMortgageLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.shareOfMortgageLabel"></bb-content-html>
                            </span>
                            <span>£{{proposalData.mortgage_rental_expenditure}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="dependantCareCostLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.dependantCareCostLabel"></bb-content-html>
                            </span>
                            <span>£{{proposalData.other_expenditure}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="changeInCircumstancesLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.changeInCircumstancesLabel"></bb-content-html>
                            </span>
                            <span>{{changeInCircumstances?.title}}</span>
                        </div>
                            <button class="wss-button-secondary mt-10px" test_id="editButtonTextFinance" id="wss-brg-summary-financeEditBtn" (click)="navigateTo('/balloon-refinance-getdetails/finances')">
                                {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                            </button>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel  class="mat-expansion-panel-override mat-expansion-panel-spacing-override">
                    <mat-expansion-panel-header>                
                        <mat-panel-title class="mat-expansion-panel-header-title-override text-semibold font-size-16 balloon-refinance-accordion-header balloon-refinance-accordion-header-color" test_id="bankAccountAccordionHeader">                    
                            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.bankAccountAccordionHeader"></bb-content-html>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="accountHolderLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.accountHolderLabel"></bb-content-html>
                            </span>
                            <span>{{proposalData.bank_account_name}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="sortCodeLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.sortCodeLabel"></bb-content-html>
                            </span>
                            <span>{{bankSortCode}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="accountNumberLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.accountNumberLabel"></bb-content-html>
                            </span>
                            <span>{{proposalData.bank_account_number}}</span>
                        </div>
                        <div class="mb-20px">
                            <span class="d-block mb-p-0" test_id="accountHeldForLabel">
                                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.accountHeldForLabel"></bb-content-html>
                            </span>
                            <span>{{proposalData.years_with_bank}} years and {{proposalData.months_with_bank}} months</span>
                        </div>
                            <button class="wss-button-secondary mt-10px" test_id="editButtonTextBank" id="wss-brg-summary-bankAccountEditBtn" (click)="navigateTo('/balloon-refinance-getdetails/bank-account')">
                                {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.editButtonText}}
                            </button>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="section-sub-title mb-20px mt-25px" test_id="aboutCreditSearchHeader"> 
           {{brGetdetailsItem.balloonRefinanceGetdetailsSummary.aboutCreditSearchHeader}}
        </div>
        <div class="mb-20px" test_id="creditSearchDetails">
            <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.creditSearchDetails"></bb-content-html>
        </div>
        <div class="card p-20px mb-10px">
            <div test_id="doYouAgreeText">
                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.doYouAgreeText"></bb-content-html>
            </div>
            <div test_id="checkboxText">
                <mat-checkbox [(ngModel)]="agreementConfirmation">{{brGetdetailsItem.balloonRefinanceGetdetailsSummary.checkboxText}}</mat-checkbox>
            </div>
            <div class="mt-5px text-danger" test_id="confirmationCheckboxError" *ngIf="!agreementConfirmation && showCheckboxError">
                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.confirmationCheckboxError"></bb-content-html>
            </div>
        </div>
        <div class="mb-40px">
            <wss-common-nav-buttons
                [content]="brGetdetailsItem.balloonRefinanceGetdetailsNavBtns"
                [accountsDetails]="accountDetails"
                [previousPageLink]="previousPageLink"
                [lastDateOfDecision]="lastDateOfDecision"
                [testIdText]="commonNavTestID"
                [isPrimaryDisabled]="isSubmitBtnDisabled"
                (primaryButtonClicked)="onSaveButtonClick($event)"
                (continueButtonClicked)="onContinue($event, loaderModal)">
            </wss-common-nav-buttons>
        </div>
    </section>

    <ng-template #loaderModal let-modal>
        <div class="modal-header horse-logo pt-40px">
            <span class="model-logo-img"></span>
        </div>
        <div class="modal-body loader-modal pb-40px" role="dialog">
            <div class="loader"></div>
            <div class="text-center">
                <bb-content-html [html]="brGetdetailsItem.balloonRefinanceGetdetailsSummary.loaderContent"></bb-content-html>
            </div>
        </div>
    </ng-template>

</section>