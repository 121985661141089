import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@backbase/universal-ang/content';
import { 
  injectAccountsDataIntoPage, 
  isErrServer, 
  isPreviouslyStartedBrJourney, 
  setResumeBrApplciationLink, 
  getDeviceType } from '@wss/common/util/util';
import { BalloonRefinanceItem } from '@wss/model/balloon-refinance/balloon-refinance';
import { DatastoreService } from '@wss/service/datastore.service';
import { Observable, Subject } from 'rxjs';
import { TealiumUtagService } from '@wss/service/utag.service';
import { EndOfContractService } from '@wss/end-of-contract-widget/src/services/end-of-contract.service';
import { ProposalRequest } from '@wss/model/balloon-refinance-getdetails/balloon-refinance-getdetails';
import { BannerMessage } from '@wss/model/bannerMessage';
import { BalloonRefinanceService } from '../../services/balloon-refinance.service';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '@wss/service/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { proposalStatus } from '@wss/config/wss-app-constants';

@Component({
  selector: 'bb-br-landing',
  templateUrl: 'br-landing.component.html',
  styles: [
  ]
})
export class BrLandingComponent implements OnInit {

  balloonRefinanceWidgetItem$: Observable<BalloonRefinanceItem | undefined> = this.bbContentService.getContent<BalloonRefinanceItem>('balloonRefinanceWidgetItem');
  accountData: any;
  howItWorksTestId: string = "howItWorksListItem";
  brWidgetItemAccordion: any;
  proposalDetails: ProposalRequest;
  bannerMessage: BannerMessage = new BannerMessage();
  readonly destroy$ = new Subject();
  isBrLandingPageBanner: boolean = true;
  isResumableBrApplication: boolean = false;
  resumeBrApplicationLink: string ='';
  resumeBrApplicationLinkText: string ='';
  continueApplicationText: string = '';
  isBrLandingPageResumeBrAppBannerClosed: boolean = false;
  serviceError: boolean = false;
  decisionData: any;
  eocBrReturnAndSignText: any;
  proposalStatus: any;
  deviceType: string = 'Mobile';
  isDecisionChanged: any;
  reviewYourApplicationResultText: any;
  landingModalPrimaryBtnId: string = '';

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.deviceType = getDeviceType();
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private readonly bbContentService: ContentService,
    private datastoreService: DatastoreService,
    private changeDetector: ChangeDetectorRef,
    private tealiumService: TealiumUtagService,
    private eocService: EndOfContractService,
    private brService: BalloonRefinanceService,
    private messageService: MessageService
    ) {
      this.proposalDetails = this.datastoreService.getProposalRequest();
    }

  ngOnInit(): void {
    this.deviceType = getDeviceType();
    this.tealiumService.view({ tealium_event:'Balloon_Refinance_Landing', URI: '/dashboard#/balloon-refinance/landing' });
    this.accountData = this.datastoreService.getAccountDetails();
    this.balloonRefinanceWidgetItem$.subscribe(content => {
      this.brWidgetItemAccordion = injectAccountsDataIntoPage(content?.balloonRefinanceLanding.whatYouNeedToKnowAccordions, this.accountData)
      this.changeDetector.detectChanges();
    })
    this.proposalStatus = this.proposalDetails?.proposal_status;
    this.isDecisionChanged = this.proposalDetails?.IsDecision_Changed === null ? false : this.proposalDetails?.IsDecision_Changed;
    if (this.proposalDetails && !(this.proposalStatus == proposalStatus.declined && this.isDecisionChanged === false)) {
      this.setBrResumeApplicationBanner();
      this.isBrLandingPageResumeBrAppBannerClosed = this.datastoreService.getIsBrLandingPageResumeBrAppBannerClosed();
    }
    this.setLandingModalBtnId();
  }

  showError(errStatus: number): void {
    if (isErrServer(errStatus)) {
      this.datastoreService.setErrorServer();
      this.serviceError = true;
      this.changeDetector.detectChanges();
    }
  }

  setBrResumeApplicationBanner() {
    if (this.proposalDetails) {
      this.isResumableBrApplication = isPreviouslyStartedBrJourney(this.proposalDetails);
      this.resumeBrApplicationLink = setResumeBrApplciationLink(this.proposalDetails, this.accountData, this.eocService.isContractWithinDays(14));
    
      this.changeDetector.detectChanges();
      this.bannerMessage.closeIconClass = "clear";
      this.bannerMessage.infoTypeClass = "icon_warining_amber";
      this.bannerMessage.leftSectionColorClass = "message-warning";
      this.bannerMessage.materialIconClass = "warning_amber";
      
      this.balloonRefinanceWidgetItem$.pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.continueApplicationText = res?.balloonRefinanceLanding?.currentOngoingRefinanceApplicationText;
        this.resumeBrApplicationLinkText = res?.balloonRefinanceLanding?.continueApplicationLinkText;
        this.bannerMessage.isBannerClosed = false;
        this.messageService.setBannerMessage(this.bannerMessage);
        this.eocBrReturnAndSignText = res?.balloonRefinanceLanding?.returnAndSignText;
        this.reviewYourApplicationResultText = res?.balloonRefinanceLanding?.reviewYourApplicationResultText;
        this.changeDetector.detectChanges();
      })
    }
  }

  openModal(modalContent: any) {
    this.modalService.open(modalContent, { centered: this.deviceType === 'Mobile' ? false : true, windowClass: 'eoc-modal' });
  }

  onQuote(modalContent: any) {
    if(this.isResumableBrApplication) {
      this.openModal(modalContent);
    } else {
      const isAgreementInArrears = (this.accountData?.in_arrears);
      const isOutOfHours = new Date().getHours() >= 22 || new Date().getHours() < 8;
      if(isOutOfHours) {
        this.router.navigate(['/balloon-refinance/cta-out-of-hours'])
      } else if (isAgreementInArrears) {
        this.router.navigate(['/balloon-refinance/cta-arrears'])
      } else {
      this.router.navigate(['/balloon-refinance/get-a-quote']);
      }
    }    
  }

  onPrimaryButtonOnModalClick() {  
    this.modalService.dismissAll();  
    this.router.navigate([this.resumeBrApplicationLink.substring(1)])
  }

  onCancelLink() {
    this.modalService.dismissAll();
    this.router.navigate(['/eoc-options/landing']);
  }

  setLandingModalBtnId() {
    this.landingModalPrimaryBtnId = this.proposalStatus === 'REFERRED' || this.isDecisionChanged === true ? 'wss-br-btn-landing-modal-review' : 
    this.proposalStatus === 'ACCEPTED' && this.isDecisionChanged === false ? 'wss-br-btn-landing-modal-Return' : 'wss-br-btn-landing-modal-continueApp'
  }
}
